@import '@/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.featuredImagePlaceholder {
  flex: 0 0 300px;
  background: var(--bs-secondary-bg);
}

.hover {
  color: var(--bs-body-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
