@import '@/styles/variables';

.cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: $card-border-radius 0 0 $card-border-radius;

  @include media-breakpoint-up(md) {
    width: 300px;
    height: 100%;
  }
}

.image {
  max-width: 100%;
  max-height: 500px;
  margin: 0 auto;
}
